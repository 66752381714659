<template>
  <v-app>
    <router-view :key="$route.path" />
  </v-app>
</template>

<script>
export default {
  name: 'App',

  async mounted() {
    const name = await this.resolveRoute();

    if (name === 'header') {
      const html = document.querySelector('html');
      const app = document.getElementById('app');

      app.style.backgroundColor = 'transparent';
      html.style.overflow = 'hidden';
    }
  },

  methods: {
    resolveRoute() {
      return new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (this.$route?.name) {
            clearInterval(intervalId);
            resolve(this.$route.name);
          }
        }, 100);
      });
    },
  },

};
</script>

<style>
