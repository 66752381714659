import service from '@/services/SIRdoc_wgt_url';

export default {
  namespaced: true,

  actions: {
    /**
     * Permite obtener los parámetros de un WebApp Vue.
     */
    read({ dispatch, rootGetters }, value) {
      return new Promise((resolve, reject) => {
        const payload = {
          dsSIRdoc_trans: {
            eeDatos: [rootGetters['auth/state']],
            eeSIRdoc_trans: [
              {
                pictrans_ext_id: value?.pictrans_ext_id,
                picwgt_props: value?.picwgt_props || '',
                piiwgt_uuid: value?.piiwgt_uuid,
              },
            ],
          },
        };

        let notification;

        service
          .post(payload)
          .then((response) => {
            const data = response.data?.dspayload;

            if (data?.eeEstados?.[0]?.Returnid === 0) {
              resolve(data);
            } else if (data?.eeEstados?.[0]?.Returnid === 98) {
              resolve([]);
            } else {
              notification = {
                code: data?.eeEstados?.[0]?.Returnid || null,
                message: data?.eeEstados?.[0]?.Estado || null,
                timestamp: Date.now(),
                type: 'error',
              };

              throw notification;
            }
          })
          .catch((error) => {
            if (error?.type === undefined) {
              notification = {
                code: error?.name || null,
                message: error?.message || null,
                timestamp: Date.now(),
                type: 'error',
              };
            }

            dispatch('notification/create', notification, {
              root: true,
            });

            reject(notification);
          });
      });
    },
  },
};
