import service from '@/services/SIRsic_sal_par';

export default {
  namespaced: true,

  actions: {
    /** Petición para obtener las cuentas de una sala.<br>
     *<br>
     * Propiedad pidsal_par_sal en payload:<br>
     * Tiene como objetivo consultar la fecha de salida de un participante de la sala.
     * Valor por defecto: '?'.
     */
    read({ dispatch, rootGetters }, value) {
      return new Promise((resolve, reject) => {
        const payload = {
          dsSIRsic_sal_par: {
            eeDatos: [rootGetters['auth/state']],
            eeSIRsic_sal_par: [
              {
                picusr__cod: value.picusr__cod,
                piisal_cod: value.piisal_cod,
                pidsal_par_sal: value?.pidsal_par_sal || '?',
              },
            ],
          },
        };

        let notification;

        service
          .read(payload)
          .then((response) => {
            const data = response.data.dsSIRsic_sal_par;

            if (data.eeEstados[0].Returnid === 0) {
              resolve(data);
            } else if (data.eeEstados[0].Returnid === 98) {
              resolve([]);
            } else {
              notification = {
                code: data.eeEstados[0].Returnid,
                message: data.eeEstados[0].Estado,
                timestamp: Date.now(),
                type: 'error',
              };

              throw notification;
            }
          })
          .catch((error) => {
            if (error.type === undefined) {
              notification = {
                code: error.name || undefined,
                message: error.message,
                timestamp: Date.now(),
                type: 'error',
              };
            }

            dispatch('notification/create', notification, {
              root: true,
            });

            reject(notification);
          });
      });
    },
  },
};
