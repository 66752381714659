import service from '@/services/SICUDsic_sal_par';

export default {
  namespaced: true,

  actions: {
    /**
     * Petición para agregar una cuenta a una sala.
     *
     * sal_cod: Obligatorio, id de sala.
     *
     * sal_par_his: Obligatorio, tipo de acceso al chat [0|1]:
     * 0: acceso al historial.
     * 1: acceso durante permanencia en sala.
     *
     * usr__cod: Obligatorio en caso de agregar una cuenta Integrity, id de usuario.
     * usr_lgn_mail: Obligatorio en caso de agregar una cuenta de email, cuenta de email.
     */
    async update({ dispatch, rootGetters }, values) {
      return new Promise((resolve, reject) => {
        const payload = {
          dsSICUDsic_sal_par: {
            eeDatos: [rootGetters['auth/state']],
            eesic_sal_par: [
              {
                sal_cod: values.sal_cod,
                sal_par_his: values?.sal_par_his || '1',
                usr__cod: values.usr__cod || null,
                usr_lgn_mail: values.usr_lgn_mail || '',
              },
            ],
          },
        };

        let notification;

        service
          .put(payload)
          .then((response) => {
            const data = response.data.dsSICUDsic_sal_par;

            if (data.eeEstados[0].Returnid === 0) {
              resolve();
            } else if (data.eeEstados[0].Returnid === 98) {
              resolve();
            } else {
              notification = {
                code: data.eeEstados[0].Returnid,
                message: data.eeEstados[0].Estado,
                timestamp: Date.now(),
                type: 'error',
              };

              throw notification;
            }
          })
          .catch((error) => {
            if (error.type === undefined) {
              notification = {
                code: error.name || undefined,
                message: error.message,
                timestamp: Date.now(),
                type: 'error',
              };
            }

            dispatch('notification/create', notification, {
              root: true,
            });

            reject(notification);
          });
      });
    },

    /**
     * Petición para retirar una o más cuentas de una sala.
     *
     * Arreglo con una o más cuentas: cada elemento es un objeto con las siguientes propiedades:
     * <br>
     * sal_cod: Obligatorio, id de sala.
     * usr__cod: Obligatorio, id de usuario.
     */
    async delete({ dispatch, rootGetters }, values) {
      return new Promise((resolve, reject) => {
        const payload = {
          dsSICUDsic_sal_par: {
            eeDatos: [rootGetters['auth/state']],
            eesic_sal_par: values,
          },
        };

        let notification;

        service
          .delete(payload)
          .then((response) => {
            const data = response.data.dsSICUDsic_sal_par;

            if (data.eeEstados[0].Returnid === 0) {
              resolve();
            } else if (data.eeEstados[0].Returnid === 98) {
              resolve();
            } else {
              notification = {
                code: data.eeEstados[0].Returnid,
                message: data.eeEstados[0].Estado,
                timestamp: Date.now(),
                type: 'error',
              };

              throw notification;
            }
          })
          .catch((error) => {
            if (error.type === undefined) {
              notification = {
                code: error.name || undefined,
                message: error.message,
                timestamp: Date.now(),
                type: 'error',
              };
            }

            dispatch('notification/create', notification, {
              root: true,
            });

            reject(notification);
          });
      });
    },
  },
};
