import { baseApi } from '@/services/index';

const resource = process.env.VUE_APP_RESOURCE_SICUDSIC_SAL_PAR;

export default {
  put(payload) {
    return baseApi.put(resource, payload);
  },

  delete(payload) {
    return baseApi.delete(resource, {
      data: payload,
    });
  },
};
