import service from '@/services/SIRsic_sal';

export default {
  namespaced: true,

  state: {
    room: {},
  },

  mutations: {
    /**
     * Establece el valor de la lista de usuarios.
     */
    SET_ROOM(state, value) {
      state.room = value;
    },
  },

  actions: {
    /**
     * Petición para obtener la lista de usuarios principales.
     */
    async read({ commit, dispatch, rootGetters }, values) {
      commit('SET_ROOM', {});

      const payload = {
        dsSIRsic_sal: {
          eeDatos: [rootGetters['auth/state']],
          eeSIRsic_sal: [
            {
              piisal_cod: values,
            },
          ],
        },
      };

      let notification;

      await service
        .read(payload)
        .then((response) => {
          const data = response.data.dsSIRsic_sal;

          if (data.eeEstados[0].Returnid === 0) {
            commit('SET_ROOM', data.eesic_sal[0]);
          } else if (data.eeEstados[0].Returnid !== 98) {
            notification = {
              code: data.eeEstados[0].Returnid,
              message: data.eeEstados[0].Estado,
              timestamp: Date.now(),
              type: 'error',
            };

            throw notification;
          }
        })
        .catch((error) => {
          if (error.type === undefined) {
            notification = {
              code: undefined,
              message: error.message,
              timestamp: Date.now(),
              type: 'error',
            };
          }

          dispatch('notification/create', notification, {
            root: true,
          });

          throw notification;
        });
    },
  },
};
