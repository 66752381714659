/* eslint-disable camelcase */
import Vue from 'vue';
import Vuex from 'vuex';

import auth from '@/store/auth';
import headers from '@/store/headers';
import icons from '@/store/icons';
import inicioJustToken from '@/store/inicioJustToken';
import LoadTokenWa from '@/store/LoadTokenWa';
import LoginApiWa from '@/store/LoginApiWa';
import notification from '@/store/notification';
import query from '@/store/query';
import setup from '@/store/setup';
import SICUDdoc_eval_val from '@/store/SICUDdoc_eval_val';
import SICUDdocumentforinstance from '@/store/SICUDdocumentforinstance';
import SICUDsic_hlp_usu from '@/store/SICUDsic_hlp_usu';
import SICUDsic_sal_par from '@/store/SICUDsic_sal_par';
import SIgetMessage from '@/store/SIgetMessage';
import SIRdoc_wgt_url from '@/store/SIRdoc_wgt_url';
import SIRevaluationModule from '@/store/SIRevaluationModule';
import SIRgetDocuments from '@/store/SIRgetDocuments';
import SIRqsmartBitacora from '@/store/SIRqsmartBitacora';
import SIRQueryDomWa from '@/store/SIRQueryDomWa';
import SIRsic_hlp from '@/store/SIRsic_hlp';
import SIRsic_msg from '@/store/SIRsic_msg';
import SIRsic_ocr_dcli from '@/store/SIRsic_ocr_dcli';
import SIRsic_sal from '@/store/SIRsic_sal';
import SIRsic_sal_bol from '@/store/SIRsic_sal_bol';
import SIRsic_sal_nvg from '@/store/SIRsic_sal_nvg';
import SIRsic_sal_par from '@/store/SIRsic_sal_par';
import SIRsic_usr_asoc from '@/store/SIRsic_usr_asoc';
import SIRsic_usr_nit from '@/store/SIRsic_usr_nit';
import SIsendMessage from '@/store/SIsendMessage';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,

  modules: {
    auth,
    headers,
    icons,
    inicioJustToken,
    notification,
    query,
    setup,
    LoadTokenWa,
    LoginApiWa,
    SICUDdoc_eval_val,
    SICUDdocumentforinstance,
    SICUDsic_hlp_usu,
    SICUDsic_sal_par,
    SIgetMessage,
    SIRdoc_wgt_url,
    SIRevaluationModule,
    SIRgetDocuments,
    SIRqsmartBitacora,
    SIRQueryDomWa,
    SIRsic_hlp,
    SIRsic_msg,
    SIRsic_ocr_dcli,
    SIRsic_sal,
    SIRsic_sal_bol,
    SIRsic_sal_nvg,
    SIRsic_sal_par,
    SIRsic_usr_asoc,
    SIRsic_usr_nit,
    SIsendMessage,
  },
});
