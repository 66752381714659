export default {
  namespaced: true,

  state: {
    messages: [],
  },

  mutations: {
    CREATE_MESSAGE(state, notification) {
      state.messages.push(notification);
    },

    DELETE_MESSAGE(state, notification) {
      state.messages = state.messages.filter(
        (message) => message.timestamp !== notification.timestamp,
      );
    },
  },

  actions: {
    create({ commit }, notification) {
      commit('CREATE_MESSAGE', notification);
    },

    delete({ commit }, notification) {
      commit('DELETE_MESSAGE', notification);
    },
  },
};
