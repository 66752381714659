export default {
  namespaced: true,

  state: {
    account: {
      value: 'mdi-account',
    },
    'account-subscription': {
      value: 'mdi-account-check',
    },
    'account-group': {
      value: 'mdi-account-group',
    },
    'account-lock': {
      value: 'mdi-account-lock',
    },
    'account-multiple-check': {
      value: 'mdi-account-multiple-check',
    },
    'account-multiple-plus': {
      value: 'mdi-account-multiple-plus',
    },
    'account-show': {
      value: 'mdi-account-eye',
    },
    'application-outline': {
      value: 'mdi-application-outline',
    },
    domain: {
      value: 'mdi-domain',
    },
    default: {
      value: 'mdi-star',
    },
    carousel: {
      value: 'mdi-view-carousel-outline',
    },
    chat: {
      value: 'mdi-message-text-outline',
    },
    check: {
      value: 'mdi-check-bold',
    },
    clock: {
      value: 'mdi-clock-time-three-outline',
    },
    close: {
      value: 'mdi-close',
    },
    collapse: {
      value: 'mdi-arrow-collapse',
    },
    'content-save-move': {
      value: 'mdi-content-save-move',
    },
    delete: {
      value: 'mdi-delete',
    },
    download: {
      value: 'mdi-download',
    },
    edit: {
      value: 'mdi-pencil',
    },
    expand: {
      value: 'mdi-arrow-expand',
    },
    eye: {
      value: 'mdi-eye',
    },
    folder: {
      value: 'mdi-folder-open-outline',
      close: 'mdi-folder-outline',
    },
    help: {
      value: 'mdi-help-circle',
    },
    history: {
      value: 'mdi-history',
    },
    information: {
      value: 'mdi-information',
    },
    logout: {
      value: 'mdi-logout',
    },
    magnify: {
      value: 'mdi-magnify',
    },
    'menu-down': {
      value: 'mdi-menu-down',
    },
    'map-market': {
      value: 'mdi-map-marker',
    },
    messenger: {
      value: 'mdi-file-document-edit-outline',
    },
    microphone: {
      value: 'mdi-microphone',
    },
    minus: {
      value: 'mdi-minus',
    },
    'office-building': {
      value: 'mdi-office-building',
    },
    plus: {
      value: 'mdi-plus',
    },
    qsmart: {
      value: 'mdi-text-box-search',
    },
    reset: {
      value: 'mdi-autorenew',
    },
    rooms: {
      value: 'mdi-file-tree',
    },
    send: {
      value: 'mdi-send',
    },
    'system-messages': {
      value: 'mdi-message-cog-outline',
    },
    'timer-outline': {
      value: 'mdi-timer-outline',
    },
    today: {
      value: 'mdi-calendar-today',
    },
    upload: {
      value: 'mdi-upload',
    },
    whatsapp: {
      value: 'mdi-whatsapp',
    },
  },
};
