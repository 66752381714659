import service from '@/services/SIgetMessage';

export default {
  namespaced: true,

  state: {
    interval: 500,
    intervalId: null,
  },

  mutations: {
    /** Identificar de la función setInterval() */
    SET_INTERVAL_ID(state, value) {
      state.intervalId = value;
    },
  },

  actions: {
    /**
     * Obtiene los mensajes de una sala.
     * pic_msg_tip: Opcional, tipo de mensaje [sistema|usuario|*|'']; defecto: *|''
     * piisal_cod: Obligatorio, id de sala.
     * piityp_view: Opcional, tipo de vista [1|2]; 1: Transaccional, 2: Chat.
     */
    async read({ dispatch, rootGetters }, values) {
      return new Promise((resolve, reject) => {
        const payload = {
          dsSIgetMessage: {
            eeDatos: [rootGetters['auth/state']],
            eeSIgetMessage: [
              {
                pic_msg_tip: values?.pic_msg_tip,
                piisal_cod: values?.piisal_cod,
                piityp_view: values?.piityp_view,
              },
            ],
          },
        };

        let notification;

        service
          .read(payload)
          .then((response) => {
            const data = response.data.dsSIgetMessage;

            if (data.eeEstados[0].Returnid === 0) {
              resolve(data?.env_msg_sock);
            } else if (data.eeEstados[0].Returnid === 98) {
              resolve([]);
            } else {
              notification = {
                code: data.eeEstados[0].Returnid,
                message: data.eeEstados[0].Estado,
                timestamp: Date.now(),
                type: 'error',
              };

              throw notification;
            }
          })
          .catch((error) => {
            if (error.type === undefined) {
              notification = {
                code: error.name || undefined,
                message: error.message,
                timestamp: Date.now(),
                type: 'error',
              };
            }

            dispatch('notification/create', notification, {
              root: true,
            });

            reject(notification);
          });
      });
    },
  },
};
