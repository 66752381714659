import service from '@/services/SIRQueryDomWa';

export default {
  namespaced: true,

  actions: {
    /**
     * Permite obtener los parámetros de un WebApp Vue.
     */
    read({ dispatch, rootGetters }, value) {
      return new Promise((resolve, reject) => {
        const payload = {
          dsQueryData: {
            eeDatos: [rootGetters['auth/state']],
            eedom_wa: [
              {
                wcp_nwa: value.domain,
              },
            ],
          },
        };

        let notification;

        service
          .read(payload)
          .then((response) => {
            const data = response.data?.dsQueryData;
            if (data?.eeEstados?.[0]?.Returnid === 0) {
              resolve(data);
            } else if (data?.eeEstados?.[0]?.Returnid === 98) {
              resolve([]);
            } else {
              notification = {
                code: data?.eeEstados?.[0]?.Returnid || null,
                message: data?.eeEstados?.[0]?.Estado || null,
                timestamp: Date.now(),
                type: 'error',
              };

              throw notification;
            }
          })
          .catch((error) => {
            if (error?.type === undefined) {
              notification = {
                code: error?.name || null,
                message: error?.message || null,
                timestamp: Date.now(),
                type: 'error',
              };
            }

            dispatch('notification/create', notification, {
              root: true,
            });

            reject(notification);
          });
      });
    },
  },
};
