export default {
  namespaced: true,

  state: {
    accountId: undefined,
    token: undefined,
  },

  mutations: {
    SET_ACCOUNT_ID(state, data) {
      state.accountId = data;
    },

    SET_TOKEN(state, token) {
      state.token = token;
    },
  },

  actions: {
    configuration({ commit }, object) {
      commit('SET_ACCOUNT_ID', object.accountId);
      commit('SET_TOKEN', object.token);
    },
  },

  getters: {
    state(state) {
      return {
        picfiid: state.token,
        picusrcod: state.accountId,
      };
    },
  },
};
