import service from '@/services/inicioJustToken';

export default {
  namespaced: true,

  actions: {
    /**
     * Obtiene un Token válido para realizar peticiones Qsmart.
     * picusrcod: Obligatorio, código de usuario.
     * picusrpass: Obligatorio, password de usuario.
     */
    async read(
      { dispatch },
      {
        user,
        password,
        idToken,
        isEmail,
      },
    ) {
      return new Promise((resolve, reject) => {
        let credential;

        if (user && password) {
          credential = isEmail
            ? { usr_lgn_mail: user, picusrpass: password }
            : { picusrcod: user, picusrpass: password };
        } else if (idToken) {
          credential = { pictkn_sn: idToken };
        } else {
          credential = {
            picusrcod: process.env.VUE_APP_USER_ID,
            picusrpass: process.env.VUE_APP_USER_PASS,
          };
        }

        const payload = {
          dslogin: {
            ttdatauser: [credential],
          },
        };

        let notification;

        service
          .read(payload)
          .then((response) => {
            const data = response.data.dslogin;

            if (data.eeEstados[0].Returnid === 0) {
              resolve(data?.ttdatauser?.[0]);
            } else if (data.eeEstados[0].Returnid === 98) {
              resolve([]);
            } else {
              notification = {
                code: data.eeEstados[0].Returnid,
                message: data.eeEstados[0].Estado,
                timestamp: Date.now(),
                type: 'error',
              };

              throw notification;
            }
          })
          .catch((error) => {
            if (error.type === undefined) {
              notification = {
                code: error.name || undefined,
                message: error.message,
                timestamp: Date.now(),
                type: 'error',
              };
            }

            dispatch('notification/create', notification, {
              root: true,
            });

            reject(notification);
          });
      });
    },
  },
};
