import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "HomeView" */ '@/App.vue'),
  },
  {
    /**
     * :json es un objeto javascript con los siguientes parámetros:
     *
     * colors: Array, cuyos elementos son un array de colores para un Gauge (parámetro requerido).
     *
     * data: Array, cuyos elementos son un array de datos para un Gauge (parámetro requerido).
     *
     * range: Array, cuyos elementos son objetos que especifican el range de un Gauge.
     *
     * ticks: Número, que especifica la cantidad de marcas para los Gauges.
     *
     * tooltip: Especifica si hay textos para el tooltip de los Gauges.
     */
    path: '/gauge/:json',
    name: 'gauge',
    component: () => import(/* webpackChunkName: "GaugeChartView" */ '@/views/charts/gauge/Main.vue'),
    props: (route) => {
      const json = JSON.parse(route.params.json);

      return {
        colors: json.colors,
        data: json.data,
        range: json.range,
        ticks: json.ticks,
        tooltip: json.tooltip,
      };
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "LoginView" */ '@/views/login/Main.vue'),
    props: (route) => {
      const json = route.query;

      return {
        accountId: json.accountId,
        token: json.token,
        id: json.id,
        numUser: json.user,
      };
    },
  },
  {
    path: '/social-media',
    name: 'socialMedia',
    component: () => import(/* webpackChunkName: "socialMedia" */ '@/views/socialMedia/Main.vue'),
  },
  {
    path: '/header',
    name: 'header',
    component: () => import(/* webpackChunkName: "HeaderView" */ '@/views/header/Main.vue'),
  },
  {
    /**
     * :json es un objeto javascript con los siguientes parámetros:
     *
     * accountId: String, su valor es el código del usuario en login (parámetro requerido).
     *
     * closeable: String, su valor indica si el app debe incluir el botón cerrar.
     *
     * externalId: String, su valor lo personaliza el usuario de acuerdo con el contexto de su
     * aplicación (parámetro requerido).
     *
     * properties: String, busca pasar props de manera directa a la vista Vue objetivo.
     * La estructura de datos de 'properties' corresponde a la estructura Json de la propiedad
     * 'picwgt_props' del rest 'SIRdoc_wgt_url'.
     *
     * token: String, su valor es el token del usuario en login (parámetro requerido).
     *
     * widgetId: String, su valor es el id (uuid) widget del usuario en login (parámetro requerido).
     */
    path: '/webapp/:json',
    name: 'webapp',
    component: () => import(/* webpackChunkName: "WebAppView" */ '@/views/webapp/Main.vue'),
    props: (route) => {
      const json = JSON.parse(route.params.json);

      return {
        accountId: json.accountId,
        closeable: json.closeable,
        externalId: json.externalId,
        properties: json.properties,
        token: json.token,
        widgetId: json.widgetId,
      };
    },
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
