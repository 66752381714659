import service from '@/services/SICUDsic_hlp_usu';

export default {
  namespaced: true,

  actions: {
    /**
     * Petición para obtener labels registrados en Integrity que serán utilizados en una vista.
     */
    async read({ dispatch, rootGetters }) {
      return new Promise((resolve, reject) => {
        const payload = {
          dsSICUDsic_hlp_usu: {
            eeDatos: [rootGetters['auth/state']],
            eesic_hlp_usu: [
              {
                hlp_cod: 1,
                hlp_con: 0,
                hlp_sec: 3,
                usu_cod: rootGetters['auth/state'].picusrcod,
                piindicador: 1,
              },
            ],
          },
        };

        let notification;

        service
          .post(payload)
          .then((response) => {
            const data = response.data.dsSIRsic_hlp;

            if (data.eeEstados[0].Returnid === 0) {
              resolve(data);
            } else if (data.eeEstados[0].Returnid === 98) {
              resolve([]);
            } else {
              notification = {
                code: data.eeEstados[0].Returnid,
                message: data.eeEstados[0].Estado,
                timestamp: Date.now(),
                type: 'error',
              };

              throw notification;
            }
          })
          .catch((error) => {
            if (error.type === undefined) {
              notification = {
                code: undefined,
                message: error.message,
                timestamp: Date.now(),
                type: 'error',
              };
            }

            dispatch('notification/create', notification, {
              root: true,
            });

            reject(notification);
          });
      });
    },
  },
};
