import service from '@/services/SIRsic_usr_asoc';

export default {
  namespaced: true,

  state: {
    mainAccountId: null,
  },

  mutations: {
    /** Establece el código del usuario principal. */
    SET_MAIN_USER_CODE(state, data) {
      state.mainAccountId = data;
    },
  },

  actions: {
    /** Obtiene el código del usuario principal. */
    read({ commit, dispatch, rootGetters }) {
      return new Promise((resolve, reject) => {
        const payload = {
          dsSIRsic_usr_asoc: {
            eeDatos: [rootGetters['auth/state']],
          },
        };

        let notification;

        service
          .read(payload)
          .then((response) => {
            const data = response.data.dsSIRsic_usr_asoc;

            if (data.eeEstados[0].Returnid === 0) {
              const mainAccount = data.eesic_usr_rel.find((user) => user.usr_asoc
                === rootGetters['auth/state'].picusrcod);

              if (mainAccount?.usr_pri) {
                commit('SET_MAIN_USER_CODE', mainAccount.usr_pri);
              }
              resolve();
            } else if (data.eeEstados[0].Returnid === 98) {
              resolve([]);
            } else {
              notification = {
                code: data.eeEstados[0].Returnid,
                message: data.eeEstados[0].Estado,
                timestamp: Date.now(),
                type: 'error',
              };

              throw notification;
            }
          })
          .catch((error) => {
            if (error.type === undefined) {
              notification = {
                code: error.name || undefined,
                message: error.message,
                timestamp: Date.now(),
                type: 'error',
              };
            }

            dispatch('notification/create', notification, {
              root: true,
            });

            reject(notification);
          });
      });
    },
  },
};
