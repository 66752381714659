import icons from './icons';

export default {
  namespaced: true,

  state: {
    messenger: {
      /* Arreglo de botones soportado por Integrity Messenger
      [
        {
          color: '',
          disabled: false,
          id: 'chat',
          tooltip: '',
          value: icons.state.chat.value,
        },
        {
          color: '',
          disabled: false,
          id: 'folder',
          tooltip: '',
          value: icons.state.folder.value,
        },
        {
          color: '',
          disabled: false,
          id: 'account-group',
          tooltip: '',
          value: icons.state['account-group'].value,
        },
        {
          color: '',
          disabled: false,
          id: 'account-subscription',
          tooltip: '',
          value: icons.state['account-subscription'].value,
        },
        {
          color: '',
          disabled: false,
          id: 'rooms',
          tooltip: '',
          value: icons.state.rooms.value,
        },
        {
          color: '',
          disabled: false,
          id: 'qsmart',
          tooltip: '',
          value: icons.state.qsmart.value,
        },
      ]
      */
      buttons: {
        accountGroup: [
          {
            color: '',
            disabled: false,
            id: 'account-delete',
            tooltip: '',
            value: icons.state.delete.value,
          },
          {
            color: '',
            disabled: true,
            id: 'accounts-add',
            tooltip: '',
            value: icons.state.plus.value,
          },
          {
            color: '',
            disabled: true,
            id: 'email-add',
            tooltip: '',
            value: icons.state.plus.value,
          },
        ],

        chat: [
          {
            color: '',
            disabled: false,
            id: 'microphone',
            tooltip: '',
            value: icons.state.microphone.value,
          },
          {
            color: '',
            disabled: false,
            id: 'account-lock',
            tooltip: '',
            value: icons.state['account-lock'].value,
          },
          {
            color: '',
            disabled: false,
            id: 'send',
            tooltip: '',
            value: icons.state.send.value,
          },
        ],

        folder: [
          {
            color: '',
            disabled: false,
            id: 'plus',
            tooltip: '',
            value: icons.state.plus.value,
          },
          {
            color: '',
            disabled: false,
            id: 'default',
            tooltip: '',
            value: icons.state.default.value,
          },
          {
            color: '',
            disabled: false,
            id: 'download',
            tooltip: '',
            value: icons.state.download.value,
          },
          {
            color: '',
            disabled: false,
            id: 'delete',
            tooltip: '',
            value: icons.state.delete.value,
          },
          {
            color: '',
            disabled: false,
            id: 'information',
            tooltip: '',
            value: icons.state.information.value,
          },
          {
            color: '',
            disabled: false,
            id: 'carousel',
            tooltip: '',
            value: icons.state.carousel.value,
          },
          {
            color: '',
            disabled: false,
            id: 'history',
            tooltip: '',
            value: icons.state.history.value,
          },
        ],

        main: [
          {
            color: '',
            disabled: false,
            id: 'chat',
            tooltip: '',
            value: icons.state.chat.value,
          },
          {
            color: '',
            disabled: false,
            id: 'files',
            tooltip: '',
            value: icons.state.folder.value,
          },
        ],

        readOnly: [
          {
            color: '',
            disabled: false,
            id: 'chat',
            tooltip: '',
            value: icons.state.chat.value,
          },
          {
            color: '',
            disabled: false,
            id: 'folder',
            tooltip: '',
            value: icons.state.folder.value,
          },
        ],

        qsmart: [
          {
            color: '',
            disabled: false,
            id: 'check',
            tooltip: '',
            value: icons.state.check.value,
          },
        ],
      },
    },
  },

  getters: {
    accountGroupButtons(state) {
      return state.messenger.buttons.accountGroup;
    },

    chatButtons(state) {
      return state.messenger.buttons.chat;
    },

    folderButtons(state) {
      return state.messenger.buttons.folder;
    },

    mainButtons(state) {
      return state.messenger.buttons.main;
    },

    qsmartButtons(state) {
      return state.messenger.buttons.qsmart;
    },

    readOnlyButtons(state) {
      return state.messenger.buttons.readOnly;
    },
  },
};
