import service from '@/services/query';

export default {
  namespaced: true,

  actions: {
    /** Petición para realizar un lookup a partir de un id y una cadena de búsqueda. */
    read({ dispatch, rootGetters }, value) {
      return new Promise((resolve, reject) => {
        const payload = {
          dsSIlookupCommon: {
            eeDatos: [rootGetters['auth/state']],
            eeSIRSIlookupCommon: [
              {
                inputFilter: value.inputFilter,
                inputSearch: value.inputSearch,
                lkp_id: value.lkp_id,
              },
            ],
            sic_lkp_inf: value.sic_lkp_inf,
          },
        };

        let notification;

        service
          .read(payload)
          .then((response) => {
            const data = response.data.dsSIlookupCommon;

            if (data.eeEstados[0].Returnid === 0) {
              const items = [];
              data.items.forEach((element) => {
                items.push(element);
              });

              resolve(items);
            } else if (data.eeEstados[0].Returnid === 98) {
              resolve([]);
            } else {
              notification = {
                code: data.eeEstados[0].Returnid,
                message: data.eeEstados[0].Estado,
                timestamp: Date.now(),
                type: 'error',
              };

              throw notification;
            }
          })
          .catch((error) => {
            if (error.type === undefined) {
              notification = {
                code: error.name || undefined,
                message: error.message,
                timestamp: Date.now(),
                type: 'error',
              };
            }

            dispatch('notification/create', notification, {
              root: true,
            });

            reject(notification);
          });
      });
    },
  },
};
