import service from '@/services/SIRsic_msg';

export default {
  namespaced: true,

  actions: {
    /**
     * Petición para obtener labels registrados en Integrity que serán utilizados en una vista.
     */
    async read({ dispatch, rootGetters }, values) {
      return new Promise((resolve, reject) => {
        const payload = {
          dssic_msg: {
            eeDatos: [rootGetters['auth/state']],
            eesic_msg: [values],
          },
        };

        let notification;

        service
          .read(payload)
          .then((response) => {
            const data = response.data.dssic_msg;

            if (data.eeEstados[0].Returnid === 0) {
              resolve(data);
            } else if (data.eeEstados[0].Returnid === 98) {
              resolve([]);
            } else {
              notification = {
                code: data.eeEstados[0].Returnid,
                message: data.eeEstados[0].Estado,
                timestamp: Date.now(),
                type: 'error',
              };

              throw notification;
            }
          })
          .catch((error) => {
            if (error.type === undefined) {
              notification = {
                code: undefined,
                message: error.message,
                timestamp: Date.now(),
                type: 'error',
              };
            }

            dispatch('notification/create', notification, {
              root: true,
            });

            reject(notification);
          });
      });
    },
  },
};
