import service from '@/services/LoadTokenWa';

export default {
  namespaced: true,

  actions: {
    async update({ dispatch, rootGetters }, value) {
      return new Promise((resolve, reject) => {
        const payload = {
          dsSICUDdoc_reg_stk: {
            eeDatos: [rootGetters['auth/state']],
            eedoc_reg_stk: [
              {
                stk_wau: value?.user,
                stk_usu: value?.picusrcod,
                wcp_nwa: value?.id,
                stk_tkn: value?.accessToken,
              },
            ],
          },
        };

        let notification;

        service
          .update(payload)
          .then((response) => {
            const { data } = response;

            if (data.dsSICUDdoc_reg_stk.eeEstados[0].Returnid === 0) {
              resolve(data.dsSICUDdoc_reg_stk.eeEstados[0].Estado);
            } else if (data.dsSICUDdoc_reg_stk.eeEstados[0].Returnid === 98) {
              resolve([]);
            } else {
              notification = {
                code: data.eeEstados[0].Returnid,
                message: data.eeEstados[0].Estado,
                timestamp: Date.now(),
                type: 'error',
              };

              throw notification;
            }
          })
          .catch((error) => {
            if (error.type === undefined) {
              notification = {
                code: error.name || undefined,
                message: error.message,
                timestamp: Date.now(),
                type: 'error',
              };
            }

            dispatch('notification/create', notification, {
              root: true,
            });

            reject(notification);
          });
      });
    },
  },
};
