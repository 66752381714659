import service from '@/services/headers';

export default {
  namespaced: true,

  actions: {
    /** Petición para obtener los headers de un lookup a partir de un id.. */
    read({ dispatch, rootGetters }, value) {
      return new Promise((resolve, reject) => {
        const payload = {
          dsSIlookupCommon: {
            eeDatos: [rootGetters['auth/state']],
            eeSIRSIlookupCommon: [
              {
                lkp_id: value.lkp_id,
              },
            ],
          },
        };

        let notification;

        service
          .read(payload)
          .then((response) => {
            const data = response.data.dsSIlookupCommon;

            if (data.eeEstados[0].Returnid === 0) {
              resolve(data.headers);
            } else if (data.eeEstados[0].Returnid === 98) {
              resolve([]);
            } else {
              notification = {
                code: data.eeEstados[0].Returnid,
                message: data.eeEstados[0].Estado,
                timestamp: Date.now(),
                type: 'error',
              };

              throw notification;
            }
          })
          .catch((error) => {
            if (error.type === undefined) {
              notification = {
                code: error.name || undefined,
                message: error.message,
                timestamp: Date.now(),
                type: 'error',
              };
            }

            dispatch('notification/create', notification, {
              root: true,
            });

            reject(notification);
          });
      });
    },
  },
};
