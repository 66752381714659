import service from '@/services/SIsendMessage';

export default {
  namespaced: true,

  actions: {
    /** Crea un nuevo mensaje de texto en una sala. */
    create({ dispatch, rootGetters }, value) {
      return new Promise((resolve, reject) => {
        const payload = {
          dsSIsendMessage: {
            eeDatos: [rootGetters['auth/state']],
            eesic_sal_msg: [
              {
                eesic_msg_dst: value?.eesic_msg_dst || [],
                eesic_sal_par: value?.eesic_sal_par || [],
                piityp_view: value?.piityp_view || null,
                salades: value?.salades || '',
                sal_cod: value?.sal_cod || '',
                sal_msg_msg: value?.sal_msg_msg || null,
                sal_msg_tip: value?.sal_msg_tip || null,
                sal_pri: value?.sal_pri || '',
                usr__cod: rootGetters['auth/state'].picusrcod,
              },
            ],
          },
        };

        let notification;

        service
          .post(payload)
          .then((response) => {
            const data = response.data.dsSIsendMessage;

            if (data.eeEstados[0].Returnid === 0) {
              resolve(data);
            } else if (data.eeEstados[0].Returnid === 98) {
              resolve([]);
            } else {
              notification = {
                code: data.eeEstados[0].Returnid,
                message: data.eeEstados[0].Estado,
                timestamp: Date.now(),
                type: 'error',
              };

              throw notification;
            }
          })
          .catch((error) => {
            if (error.type === undefined) {
              notification = {
                code: error.name || undefined,
                message: error.message,
                timestamp: Date.now(),
                type: 'error',
              };
            }

            dispatch('notification/create', notification, {
              root: true,
            });

            reject(notification);
          });
      });
    },
  },
};
