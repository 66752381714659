import service from '@/services/LoginApiWa';

export default {
  namespaced: true,

  actions: {
    /**
     * Obtiene un Token válido para realizar peticiones Qsmart.
     * picusrcod: Obligatorio, código de usuario.
     * picusrpass: Obligatorio, password de usuario.
     */
    async login(
      { dispatch, rootGetters },
      {
        user,
        password,
        accessToken,
        id,
        nuser,
      },
    ) {
      return new Promise((resolve, reject) => {
        const credential = {
          picusr_dom: id,
          picusr_nwa: nuser,
        };

        if (user && password) {
          credential.picusr_id = user;
          credential.picusr_pas = password;
        } else if (accessToken) {
          credential.pictkn_sn = accessToken;
        }

        const payload = {
          dslogin: {
            datauser: [credential],

            eeDatos: [rootGetters['auth/state']],
          },

        };

        console.log(payload);

        let notification;

        service
          .read(payload)
          .then((response) => {
            const data = response.data.dslogin;

            if (data.eeEstados[0].Returnid === 0) {
              resolve(data?.eeEstados?.[0]);
            } else if (data.eeEstados[0].Returnid === 98) {
              resolve([]);
            } else {
              notification = {
                code: data.eeEstados[0].Returnid,
                message: data.eeEstados[0].Estado,
                timestamp: Date.now(),
                type: 'error',
              };

              throw notification;
            }
          })
          .catch((error) => {
            if (error.type === undefined) {
              notification = {
                code: error.name || undefined,
                message: error.message,
                timestamp: Date.now(),
                type: 'error',
              };
            }

            dispatch('notification/create', notification, {
              root: true,
            });

            reject(notification);
          });
      });
    },
  },
};
